import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UIWrapper from "../components/UIWrapper";
import { Box, Divider, Typography } from "../mui";
import BiService from "../services/BiService";
import AdminService from "../services/AdminService";
import { useDispatch } from "react-redux";
import { authActions } from "../Store/auth";
import DOMPurify from "dompurify";
import ServicesSearchPage from "./ServicesSearchPage";
import "./HomeStyle.css";
const styles = {
  h1: {
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "116.7%",
    letterSpacing: "-1.5px",
    color: "#9b51e0",
    padding: "2px",
  },
  h1Body: {
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "116.7%",
    letterSpacing: "-1.5px",
    color: "rgba(0, 0, 0, 0.6)",
    padding: "2px",
    paddingLeft: "5px",
  },
  h3: {
    fontFamily: "Helvetica",
    fontWeight: 300,
    fontSize: "3rem",
    lineHeight: 1.167,
    letterSpacing: "-0.01562em",
  },
};

const Home = (props) => {
  const userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  const history = useHistory();
  const [userHistory, setUserHistory] = React.useState([]);
  const [html, setHTML] = useState({ __html: "" });
  const dispatch = useDispatch();

  const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };
  const secretPass = "ljhREQc6QWsdfNVB";

  useEffect(() => {
    let uniqueChars = [...new Set(props.historyArray.slice(-6))];
    // setUserHistory(uniqueChars)
    //GET user profile code below
    AdminService.getUserProfileData()
      .then((resp) => {
        dispatch(authActions.userProfile(resp.data));
      })
      .catch((err) => {
        console.log(err);
      });

    //GET services
    BiService.getAllServices()
      .then((resp) => {
        let recentList = [];
        uniqueChars.forEach((ele) => {
          resp.data.forEach((option) => {
            if (ele.includes(option.route)) {
              recentList[ele] = option;
            }
          });
        });
        setUserHistory(recentList);

        // setAvailableServices(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    createMarkup()
      .then((result) => setHTML(result))
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

  async function createMarkup() {
    let response;
    let page = "home";
    response = await AdminService.getBulletinData(page);
    const backendHtmlString = await response.data.bulletin_data;
    const sanitizedHtml = DOMPurify.sanitize(decryptData(backendHtmlString));
    return { __html: sanitizedHtml };
  }

  return (
    <UIWrapper title="Dashboard" paper={true}>
      <Box
        className="clientTableContainer"
        style={{ minHeight: "400px", padding: "8px" }}
      >
        <Typography variant="h4" color="secondary">
          Welcome To SampledSphere
        </Typography>
        {userProfile?.user_type === "internal" ? (
          <div dangerouslySetInnerHTML={html} />
        ) : (
          <ServicesSearchPage />
        )}
      </Box>
    </UIWrapper>
  );
};

export default Home;
