import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Divider,
  ExitToAppIcon,
  HomeIcon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiDrawer,
} from "../../mui";
import Tooltip from "@mui/material/Tooltip";
import ServiceSendToLIMS from "../../pages/ServiceSendToLIMS";
import BiService from "../../services/BiService";
import { logoutUser } from "../../Store/auth-actions";
import ServiceIcon from "./ServiceList/ServiceIcon";
import ListItemButton from "@mui/material/ListItemButton";
const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.duration.shortest,
    duration: 5,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: 5,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}));

const DrawerWrapper = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: "8px",
  transition: "transform .3s ease-out",
  "&.Mui-selected": {
    color: theme.palette.selectedNavItem.color,
    background: theme.palette.selectedNavItem.bgColor,
    "&:hover": {
      background: theme.palette.selectedNavItemHover.bgColor,
    },
  },
  "&.Mui-selected svg": {
    color: theme.palette.selectedNavItem.color,
  },
  "&:hover": {
    background: theme.palette.navItemHover.bgColor,
  },
  "&.MuiListItem-root svg": {
    width: "30px",
  },
}));

const Drawer = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const path = useSelector((state) => state.navigation.path);
  const { open, setOpen } = props;
  const [sideBarOptions, setSideBarOptions] = useState([]);
  // const [adminView, setAdminView] = useState(false);

  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [isSideBarItemSelected, setIsSideBarItemSelected] = useState(false);

  const location = useLocation();

  const handleClose = (view) => {
    setOpen(false);
    props.handleExpanded();
  };

  useEffect(() => {
    if (path != "/home" && path.length) {
      BiService.getSidebar(path)
        .then((resp) => {
          setSideBarOptions(
            resp.data.sort((a, b) => {
              if (a.subservice_name < b.subservice_name) {
                return -1;
              }
              if (a.subservice_name > b.subservice_name) {
                return 1;
              }
              return 0;
            })
          );
          return resp.data;
        })

        .catch((err) => {});
    } else {
      setSideBarOptions([]);
    }
  }, [path]);

  const handleOpenModal = (e, options) => {
    setOpenServiceModal(true);
  };

  return (
    isLoggedIn && (
      <>
        <DrawerWrapper variant="permanent" open={open}>
          <DrawerHeader style={{ border: "none" }} sx={{ mt: -1, mb: -1 }}>
            <IconButton
              onClick={() => handleClose()}
              style={{ color: "primary" }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon color="primary" />
              )}
            </IconButton>
          </DrawerHeader>

          <List sx={{ marginTop: "5px" }}>
            <Tooltip title="Home" placement="left">
              <CustomListItem
                component={Link}
                to="/home"
                button
                selected={path === "/home"}
              >
                <ListItemIcon
                  style={{
                    color: theme.palette.navItem.color,
                    minWidth: "28px",
                  }}
                >
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} sx={{ ml: -1, pl: 3 }} />
              </CustomListItem>
            </Tooltip>

            {sideBarOptions &&
              sideBarOptions.map((option) => (
                <Tooltip
                  key={option.subservice_name}
                  title={option.subservice_name}
                  placement="left"
                >
                  {option.subservice_route !== "modal" ? (
                    <CustomListItem
                      component={Link}
                      button
                      key={option.subservice_name}
                      to={option.subservice_route}
                      selected={location.pathname === option.subservice_route}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: "28px",
                        }}
                      >
                        <ServiceIcon
                          iconName={option.icon}
                          color={theme.palette.navItem.color}
                        />
                      </ListItemIcon>
                      <ListItemText
                        key={option.subservice_name}
                        primary={option.subservice_name}
                        sx={{ ml: -1, pl: 3 }}
                      />
                    </CustomListItem>
                  ) : (
                    <ListItemButton onClick={handleOpenModal}>
                      <ListItemIcon
                        style={{
                          minWidth: "20px",
                        }}
                      >
                        <ServiceIcon
                          iconName={option.icon}
                          color={theme.palette.navItem.color}
                        />
                      </ListItemIcon>
                      <ListItemText
                        key={option.subservice_name}
                        primary={option.subservice_name}
                        sx={{ ml: -1, pl: 3 }}
                      />
                    </ListItemButton>
                  )}
                </Tooltip>
              ))}
          </List>
          <Divider />
          <List sx={{ marginTop: "5px" }}>
            <Tooltip title="Logout" placement="left">
              <CustomListItem
                button
                key={"Logout"}
                onClick={() => dispatch(logoutUser())}
              >
                <ListItemIcon
                  style={{
                    color: theme.palette.navItem.color,
                    minWidth: "28px",
                  }}
                >
                  <ExitToAppIcon></ExitToAppIcon>
                </ListItemIcon>
                <ListItemText primary={"Logout"} sx={{ ml: -1, pl: 3 }} />
              </CustomListItem>
            </Tooltip>
          </List>
        </DrawerWrapper>

        {openServiceModal ? (
          <ServiceSendToLIMS
            openServiceModal={openServiceModal}
            setOpenServiceModal={setOpenServiceModal}
          />
        ) : null}
      </>
    )
  );
};

export default Drawer;
